body {
  margin: 0;
  font-family: "Apis", "Noto Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  display: none;
}

@font-face {
  font-family: "Apis-Bold";
  src: local("Apis-Bold"),
    url("./fonts/Apis-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Apis-BoldItalic";
  src: local("Apis-BoldItalic"),
    url("./fonts/Apis-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Apis-Italic";
  src: local("Apis-Italic"),
    url("./fonts/Apis-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Apis-Medium";
  src: local("Apis-Medium"),
    url("./fonts/Apis-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Apis";
  src: local("Apis-Regular"),
    url("./fonts/Apis-Regular.ttf") format("truetype");
}

.registrant_grid {
  background-color: #ffffff;
}

.user_grid {
  background-color: #ffffff;
}

.registrant_grid .MuiButton-root,
.learning_objectives_grid .MuiButton-root,
.user_grid .MuiButton-root {
  width: auto;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  /* line-height: 1.75; */
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #1976d2;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  background-color: #158BEE;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

.registrant_grid .MuiButton-root:hover,
.learning_objectives_grid .MuiButton-root:hover,
.user_grid .MuiButton-root:hover {
  text-decoration: none;
  background-color: #1565c0;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}


.slide_wrapper img.selected {
  border: 4px solid #3B97DE !important;
  opacity: 1 !important;
}

.slide_wrapper img.previously-selected {
  border: 4px solid #bebebe;
}

.slide_wrapper.selected {
  opacity: 1 !important;
}

.slide_wrapper.previously-selected {
  opacity: 0.4;
}

.slide_wrapper img {
  border: 4px solid #ffffff;
  box-shadow: 0px 1px 3px 0px #909090;
  border-radius: 4px;
}

.activePresentations .MuiListItemButton-root {
  border-right: 4px solid #ffffff;
  min-height: 77px;
  align-items: center;
}

.activePresentations .MuiListItemButton-root:hover {
  border-right-color: whitesmoke;
}

.activePresentations .MuiListItemButton-root.selected {
  background-color: #E2F0FA;
  border-right: 4px solid #3b97de;
}

.activePresentations .MuiListItemButton-root.selected svg.icon-drag-indicator {
  color: rgba(0, 0, 0, 0.40);
}

.activePresentations .selected:hover {
  background-color: #E2F0FA;
}

.hide {
  display: none !important;
}

.slide_number {
  margin-bottom: -10px;
  position: absolute;
  left: 6px;
  top: 10px;
  background-color: #3b97de;
  border-radius: 20px;
  padding: 5px;
  width: 15px;
  text-align: center;
  color: white;
  font-size: 12px;
  z-index: 1;
}

.MuiIconButton-root.expand_button {
  border-radius: 20px;
  background-color: #3b97de;
  padding: 2px;
}

.MuiIconButton-root.expand_button_wrapper {
  border-radius: 20px;
  background-color: #3b97de;
  color: white;
  position: absolute;
  bottom: 10px;
  right: 3px;
  padding: 5px;
}

.MuiIconButton-root.expand_button_wrapper:hover {
  background-color: #3b97de;
  opacity: 0.9;
}

.slide_modal .modal_close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #001965;
  font-size: 25px;
  padding: 0px;
  z-index: 9;
}

.slide_modal .arrows {
  color: white;

}

.slide_modal .modal_number {
  color: white;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  
}

.slide_modal .img_wrapper {
  border: 9px solid transparent;
  background-color: #ffffff;
  opacity: 1;
}

.slide_modal .img_wrapper.selected {
  border: 9px solid #3B97DE !important;
}

.slide_modal .img_wrapper.previously-selected {
  border: 9px solid #e5e5e5;
}

.slide_modal .img_wrapper img {
  opacity: 1;
}

.slide_modal .img_wrapper.previously-selected img {
  opacity: 0.5;
}

.slide_modal .img_wrapper.selected img {
  opacity: 1 !important;
}

.slide_modal img {
  max-width: 100%;
  width: 100%;
  background-color: white;
  max-height: calc(95vh - 33px);
  cursor: pointer;
}

.learning_objectives_grid {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.20);
  border: none !important;
}

.learning_objectives_grid .therapeuticArea,
.therapeuticArea {
  text-transform: capitalize;
}

.MuiDataGrid-columnHeaderTitle {
  font-family: "Apis-Medium", "Noto Sans", sans-serif;
}

.font-face-apis-bold {
  font-family: "Apis-Bold";
}

.font-face-apis-bolditalic {
  font-family: "Apis-BoldItalic";
}

.font-face-apis-italic {
  font-family: "Apis-Italic";
}

.font-face-apis-medium {
  font-family: "Apis-Medium";
}

.font-face-apis {
  font-family: "Apis";
}

#presentation_builder_learningobjectives .MuiFormControl-root {
  background-color: #FFFFFF;
}

#presentation_builder_learningobjectives {
  margin-bottom: 20px;
}

.presentation_builder .MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
#presentation_builder_learningobjectives .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(0, 0, 0, 0.23);
}

#presentation_builder__learningObjectives_input {
  background-color: #FFFFFF;
}

#presentation_builder_learningobjectives-label {
  color: rgba(0, 0, 0, 0.6);
  background-color: unset;
}

#presentation_builder_learningobjectives .MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined {
  color: rgba(0, 0, 0, 0.6);
}

.MuiModal-root .MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-outlined {
  background-color: white;
}

.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #3B97DE;
  border-width: 2px;
}

.MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3B97DE;
  border-width: 2px;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.26);
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3B97DE;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-error:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot:hover .MuiOutlinedInput-notchedOutline {
  border-color: #3B97DE;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.Mui-error:hover .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.Mui-disabled:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.26);
}

/* MuiAutocomplete-inputRoot */
.file-btn:hover {
  background-color: rgb(25, 118, 210);
  color: #ffffff;
}

.alert_message {
  margin-top: 75px;
}

/* MUI GRID TOOLDBAR */
.MuiDataGrid-toolbarContainer .MuiButtonBase-root {
  background-color: #E2F0FA;
  color: #001965;
  padding: 8px 15px;
  width: auto;
  min-width: auto;
}

.MuiDataGrid-toolbarContainer .MuiButtonBase-root:hover {
  background-color: #E2F0FA;
  color: #001965;
}

.MuiDataGrid-toolbarContainer .MuiButtonBase-root .MuiButton-startIcon {
  margin: 0px;
}

.MuiDataGrid-toolbarContainer .MuiButtonBase-root .MuiButton-startIcon>*:nth-of-type(1) {
  font-size:1.5em;
}

.MuiDataGrid-root .MuiDataGrid-toolbarContainer {
  padding-top: 10px;
}